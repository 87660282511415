.estimate-details-background-grey {
  background: radial-gradient(#f6f6f6, #fdfdfd);
  border-radius: 6px;
  border: 0px solid #eee;
}

.has-background-gradient-green {
  background: linear-gradient(135deg, hsl(171, 100%, 41%), hsl(171, 99%, 35%));
}

.estimate-details-form {
  overflow: hidden;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out, max-height 0.3s ease-out;
  opacity: 0;
  visibility: hidden;
  max-height: 0;

  &.is-active {
    opacity: 1;
    visibility: visible;
    max-height: 500px; // Dostosuj tę wartość w zależności od maksymalnej wysokości formularza
    transition: opacity 0.5s ease-in, visibility 0.5s ease-in, max-height 0.5s ease-in;
  }

  // Dodaj padding, aby uniknąć nagłego pojawienia się zawartości
  padding: 0;

  &.is-active {
    padding: 2rem 0;
  }
}

.is-selected {
  background-color: #f9f9f9;
  /* Ustaw kolor tła dla naprzemiennych wierszy */
}

.tabs-navigation {
  background-color: #fefefe;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #eee;

  .button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    button {
      flex: 1;
      min-width: min-content;
      white-space: nowrap;
    }
  }

  @media (max-width: 768px) {
    &.columns {
      flex-direction: column;
    }
  }
}