.full-width-page {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  left: 0;
  right: 0;

  .section {
    max-width: 1344px;
    margin: 0 auto;
    padding: 1rem 1.5rem 3rem 1.5rem;
  }
}


.payment-info-section {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  left: 0;
  right: 0;
  padding: 3rem 1.5rem;
  
  > div {
    max-width: 1344px;
    margin: 0 auto;
  }
  
  .payment-operator {
    text-align: center;
    margin-bottom: 2rem;
  }

  .payment-methods {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
    
    .method-tag {
      padding: 0.5rem 1rem;
      background: #f8f9fa;
      border-radius: 6px;
      font-size: 0.9rem;
      color: #555;
      transition: all 0.2s ease;
      
      &:hover {
        background: #f1f3f5;
        transform: translateY(-1px);
      }
    }
  }

  .premium-important-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;

    .info-item {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      
      i {
        font-size: 1.25rem;
        margin-top: 0.2rem;
      }
      
      p {
        color: #555;
        line-height: 1.5;
        margin: 0;
        font-size: 1rem;
      }
    }
  }
}

.pricing-features {
  list-style-type: none !important;
  padding: 0;
  margin: 0;
  
  li {
    list-style-type: none !important;
    list-style: none !important;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    
    .icon {
      flex-shrink: 0;
      margin-top: 0rem;
      margin-right: 0.75rem;
    }
    
    .feature-text {
      flex: 1;
    }
  }
}

.premium-card {
  transition: transform 0.2s ease;
  height: 100%;
  
  &:hover {
    transform: translateY(-5px);
  }
  
  &.highlighted {
    border: 2px solid #485fc7;
    box-shadow: 0 8px 16px rgba(72, 95, 199, 0.1);
  }
}

.premium-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  
  .fa-crown {
    font-size: 2.5rem;
  }
}

.period-selector {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0;
  
  @media screen and (min-width: 1124px) {
    flex-direction: row;
    justify-content: center;
  }
  
  .period-option {
    padding: 0.5rem 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    
    &:hover {
      border-color: #485fc7;
    }
    
    &.active {
      background-color: #485fc7;
      color: white;
      border-color: #485fc7;
    }
    
    .discount-badge {
      font-size: 0.8rem;
      color: #48c774;
      margin-left: 0.5rem;
      font-weight: bold;
      
      @media screen and (max-width: 767px) {
        display: inline-block;
        padding: 2px 6px;
        background-color: #48c774;
        color: white;
        border-radius: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .payment-info-section {
    padding: 2rem 1rem;
    
    .premium-important-info {
      gap: 1.5rem;
    }
  }
}
