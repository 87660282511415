@import "~bulma/sass/utilities/_all";

.has-background-gradient-blue {
  background: linear-gradient(135deg, #1e57b3, #1e88e5);
}

.estimate_main_buttons {
  background: linear-gradient(135deg, hsl(171, 100%, 41%), hsl(171, 99%, 35%));
  color: white;
}

.has-background-gradient-grey {
  background: radial-gradient(#fafafa, #ffffff);
}

.estimate {
  border: 0px solid #dddddd;
}

.summary-details {
  .table.is-bordered {
    border: none;

    td,
    th {
      border: none;
    }
  }

  .categoryTitle {
    a {
      transition: color 0.1s ease;

      &:hover {
        color: $info;

        .icon, 
        .icon i {
          color: $info !important; // Użycie !important jako ostateczność
        }
      }

      // Dodajemy styl dla ikony, gdy nie ma :hover
      .icon, 
      .icon i {
        transition: color 0.1s ease;
      }
    }
  }

  .product-list-container {
    border: 1px solid #dbdbdb; // kolor bordera, możesz dostosować
    border-radius: 8px; // zaokrąglenie rogów, możesz dostosować
    overflow: hidden; // zapewnia, że zawartość nie wychodzi poza zaokrąglone rogi
    padding: 0;
    margin: 0;

    // Usuwa margines z pierwszego i ostatniego elementu listy
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}

.control-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .panel-section {
    flex: 1;
    min-width: 250px;
    margin-bottom: 10px;

    .panel-section-title {
      margin-bottom: 15px;
      color: #333;
      font-weight: 600;
      border-bottom: 2px solid #e1e4e8;
      padding-bottom: 10px;
    }
  }

  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .radio-option {
    position: relative;
    display: block;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: all 0.3s ease;
    
    &:hover {
      border-color: rgba(50, 115, 220, 0.3);
    }

    &.is-active {
      border-color: #3273dc;
      
      .radio-content {
        background-color: rgba(50, 115, 220, 0.05);
      }
    }
  }

  .radio-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radio-content {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }

  .radio-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f3f5;
    border-radius: 8px;
    margin-right: 15px;
    color: #3273dc;
  }

  .radio-text {
    display: flex;
    flex-direction: column;
  }

  .radio-label {
    font-weight: 600;
    color: #333;
  }

  .radio-description {
    font-size: 0.8rem;
    color: #6c757d;
  }

  .actions {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    
    .action-buttons {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      justify-content: center;
    }

    .discount-input-group {
      display: flex;
      align-items: center;
      gap: 10px;

      .discount-input {
        max-width: 100px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .panel-section {
      min-width: 100%;
    }

    .actions .action-buttons {
      flex-direction: column;
      width: 100%;

      > * {
        width: 100%;
      }
    }
  }
}